<template>
  <div class="joinus">
    <a class="society flex-column-center cursor" :href="social" target="_blank">
      <p>社会招聘</p>
      <!-- <img src="../assets/images/01left.svg" alt=""> -->
      <div class="ico"></div>
      <i></i>
    </a>
    <a class="school flex-column-center cursor" :href="school" target="_blank">
      <p>校园招聘</p>
      <!-- <img src="../assets/images/02right.svg" alt=""> -->
      <i></i>
      <div class="ico"></div>
    </a>
  </div>
</template>
<script>
export default {
  data() {
    return {
      social: "",
      school: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$fetch({
        url: "index.php/joinus/initPage",
      }).then((res) => {
        // console.log(res);
        if (res.code == 0) {
          this.social = res.data.joinus.exp1;
          this.school = res.data.joinus.exp2;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.joinus .ico {
  width: 40px;
  height: 2px;
  background: #6d85bc;
  position: relative;
  position: absolute;
  top: 44px;
  left: 19px;
  transition: all 0.3s linear;
}
.joinus .ico::after {
  content: "";
  width: 2px;
  height: 8px;
  background: #6d85bc;
  position: absolute;
  top: 0;
  right: 3px;
  transform: rotate(45deg);
}
.joinus .ico::before {
  content: "";
  width: 2px;
  height: 8px;
  background: #6d85bc;
  position: absolute;
  top: -6px;
  right: 3px;
  transform: rotate(-45deg);
}
.joinus a:hover .ico {
  width: 50px;
  transition: all 0.3s linear;
}
.society .ico {
  background: #3fe6e6;
}
.society .ico::after {
  background: #3fe6e6;
}
.society .ico::before {
  background: #3fe6e6;
}
.joinus a img {
  margin-top: 10px;
  width: 42px;
  height: 18px;
}

.joinus {
  position: relative;
  height: 700px;
  background: url(../assets/images/join-bg.png) 0 0 / cover;
  margin: 40px 0 60px;
  .bg {
    width: 100%;
    height: 702px;
  }
  .society,
  .school {
    // width: 140px;
    // height: 140px;
    position: absolute;
    top: 465px;
    font-size: 20px;
    font-weight: 600;
    // i {
    //   width: 12px;
    //   height: 12px;
    //   margin-top: 8px;
    // }
  }
  .society {
    left: 850px;
    color: #fff;
    i {
      background: url("../assets/images/r1.png") 0 0 / cover no-repeat;
    }
  }
  .school {
    left: 1130px;
    color: #333;
    i {
      background: url("../assets/images/r2.png") 0 0 / cover no-repeat;
    }
  }
}

</style>